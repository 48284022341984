@import '_variables';

.container > h1 {
  margin-top: 0.5em;
}

.help-block {
  color: var(--bs-gray);
}

.warning-block {
  color: $danger;
  &::before {
    content: fa-content($fa-var-exclamation-circle) + ' ';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    padding-right: 0.3em;
  }
}

.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.link-delete {
  @extend .link;

  color: $danger;
  text-decoration: none;
  &::before {
    content: fa-content($fa-var-times) + ' ';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    padding-right: 0.3em;
  }
}
