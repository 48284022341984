@import "./variables";

// Allow for address fields to have whatever format they want
// For contact info input via textarea's
address {
  white-space: pre-line;
}

// Add ability for
label.required::after {
  content: '*';
  color: $danger;
}

ul.two-column {
  column-count: 2;
}

